import React from 'react'
import PartB from '../sections/partb';
const SectionB=()=> {
  const a="Hydration Calculator";
  const b="Your BMI is ";
  const c="79";
  const d="Your BMI suggest are obese and your ideal weight should be in between";
  const e="49kg's to 55 kg's";
  return (
    <>
    <PartB data1={a} data2={b} data3={c} data4={d} data5={e}/>
   </>
  )
}
export default SectionB;
