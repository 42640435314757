import React from 'react'
import DataForm from '../form'
import { Card, Col, Row } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';
const PartB=(props)=> {
  return (
    <>
    <h2 style={{textAlign:'center',paddingBottom:'15px'}}>{props.data1}</h2>
    <Row gutter={20}>
      <Col xs={24} sm={24} md={24} lg={12} xxl={12} xl={12} style={{marginBottom:"20px"}}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{paddingBottom:'20px'}}>
            <Card style={{height:'300px',borderRadius:'15px',backgroundColor:'aliceblue'}}>
              
            </Card>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <Card style={{borderColor:'blue',borderRadius:'50px',backgroundColor:'aliceblue'}}>
              <div style={{display:'flex'}}>
                <div style={{display:'flex',fontSize:'large'}}><p >{props.data2}</p> <p style={{color:'blue',fontWeight:'500'}}> {props.data3}</p></div>
                <hr type='vertical' style={{color:"blue", margin:"0 5px"}}></hr>
                <div>
                  <p>{props.data4}</p><p style={{color:'blue',fontWeight:'500'}}>{props.data5}</p> 
                </div>
              </div>
          </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xxl={12} xl={12} style={{marginBottom:"20px"}}>
  
        <DataForm/>
  
        </Col>      
      </Row>
      </>
  )
}
export default PartB;
