import React from 'react';
import PartD from '../sections/partd';
const SectionD = () => {
  const data = {
    a: "How To Maintain Hydration",
    b: "Go-Low Carbs",
    c: "Carbohydrates (carbs) are what cause blood sugar to rise. When you eat carbs, they are broken down into simple sugars. Those sugars then enter the bloodstream.",
    d: "As your blood sugar levels rise, your pancreas releases a hormone called which prompts your cells to absorb sugar from the blood. This causes your blood sugar levels to drop..",
    e: "Get Enough Sleep",
    f: "Both too little and too much sleep have been associated with poor blood sugar control.",
    g: "With sleep, quality is as important as quantity. A study found the deepest level of sleep (NREM) to be most important in terms of controlling blood sugar.",
    h: "Say No to Stress",
    i: "Stress can have a lot of detrimental effects on your health, including anxiety, elevated blood pressure, and headaches.",
    j: "It has also exhibited blood sugar control. Your body releases specific hormones as stress levels increase. Sugar is released into your bloodstream as a result, storing energy for the fight-or-flight response"
  };
  return (
    <>
      <PartD {...data} />
    </>
  )
};
export default SectionD;