import { Col, Row, Tabs } from "antd";
import React from "react";
import Hydration from "./hyderation/hydration";
import Glucose from "./glucose";
// import img from '../../img/hydration image.png'
const onChange = (key) => {
  console.log(key);
};

const NavBar = () => (
  <>
    {/* <img src={img}/> */}

    <Row gutter={12}>
      <div className="container px-5">
        <img src="./favicon.ico" />

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            tab={
              <span
                style={{
                  fontSize: "large",
                  marginBottom: "100px",
                  height: "5pc",
                }}
              >
                Hydration
              </span>
            }
            key="1"
          >
            <Hydration />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span
                style={{
                  fontSize: "large",
                  marginBottom: "100px",
                  height: "5pc",
                }}
              >
                Glucose
              </span>
            }
            key="2"
          >
            <Glucose />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Row>
  </>
);

export default NavBar;
