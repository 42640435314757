import React, { useState } from "react";
import { faDroplet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "antd";
import ScrollAnimation from "react-animate-on-scroll";
// import './partd.css'

const PartD = (props) => {
  console.log("glucose11", props);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const handleMouseOver1 = () => {
    setIsHovered1(true);
  };
  const handleMouseOut1 = () => {
    setIsHovered1(false);
  };
  const handleMouseOver2 = () => {
    setIsHovered2(true);
  };
  const handleMouseOut2 = () => {
    setIsHovered2(false);
  };
  const BoxStyle = () => {
    return {
      borderColor: " #1a75ff",
      borderRadius: "15px",
      color: isHovered ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered ? " #1a75ff" : "transparent",
    };
  };
  const BoxStyle1 = () => {
    return {
      borderColor: " #1a75ff",
      borderRadius: "15px",
      color: isHovered1 ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered1 ? " #1a75ff" : "transparent",
    };
  };
  const BoxStyle2 = () => {
    return {
      borderColor: " #1a75ff",
      borderRadius: "15px",
      color: isHovered2 ? "white" : "black",
      transition: "background-color 0.4s",
      backgroundColor: isHovered2 ? " #1a75ff" : "transparent",
    };
  };
  return (
    <>
      <h2>{props.a}</h2>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}>
            <Card
              style={BoxStyle()}
              className={isHovered ? "show-card" : "hide-card"}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <FontAwesomeIcon
                icon={faDroplet}
                style={{ marginRight: "10px", color: "#1a75ff" }}
              />
              <h3>{props.b}</h3>
              <p style={{ fontSize: "medium" }}>{props.c}</p>
              <p>{props.d}</p>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}>
            <Card
              style={BoxStyle1()}
              className={isHovered1 ? "show-card" : "hide-card"}
              onMouseOver={handleMouseOver1}
              onMouseOut={handleMouseOut1}
            >
              <FontAwesomeIcon
                icon={faDroplet}
                style={{ marginRight: "10px", color: "#1a75ff" }}
              />
              <h3>{props.e}</h3>
              <p style={{ fontSize: "medium" }}>{props.f}</p>
              <p>{props.g}</p>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}>
            <Card
              style={BoxStyle2()}
              className={isHovered2 ? "show-card" : "hide-card"}
              onMouseOver={handleMouseOver2}
              onMouseOut={handleMouseOut2}
            >
              <FontAwesomeIcon
                icon={faDroplet}
                style={{ marginRight: "10px", color: "#1a75ff" }}
              />
              <h3>{props.h}</h3>
              <p style={{ fontSize: "medium" }}>{props.i}</p>
              <p>{props.j}</p>
            </Card>
          </Col>
        </Row>
      </ScrollAnimation>
    </>
  );
};
export default PartD;
