import React from 'react';
import PartC from '../sections/partc';
import img from '../../img/hydration image.png'

export default function SectionC() {
  const data={
    a:'Why Importance Is Being Hydrated',
    b:' Hydration is important for overall health. Water is essential for the human body, and every cell, tissue, and organ needs it to function properly.',
    c:'helps regulate body temperature, remove waste, and lubricate joints.',
    d:'Water can help with weight loss',
    e:'Hydration can help fight infections and improve your mood',
    img:img
  }
  return (
    <>
     <PartC {...data}/>
    </>
    );
}
