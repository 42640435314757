import React from 'react';
import PartC from '../sections/partc';
import img from '../../img/MicrosoftTeams-image.png'
export default function SectionC() {
  const data={
    a:'Importance of Glucose in our body',
    b:' The glucose in the blood is a form of sugar that supplies the body power.A glucose monitor calculates how much sugar the blood is able to carry in a given moment.',
    c:'This sugar is obtainable by people through their diet.',
    d:'The bloods internal environment must remain stable to support vital bodily functions.',
    e:'After the first meal of the day, they are at their lowest point.',
    img:img
  }
  return (
    <>
     <PartC {...data}/>
    </>
  );
}


