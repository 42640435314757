import React from 'react'
import PartB from '../sections/partb';
const SectionB=()=> {
    const a="Glucose Monitoring";
    const b="Your Sugar lvl is";
    const c="79 mg/dl";
    const d="A person's fasting blood sugar levels should be belows";
    const e="99 Miligrams per Deciliter";
  return (
    <>
<PartB data1={a} data2={b} data3={c} data4={d} data5={e}/>
</>
  )
}
export default SectionB;
