import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Select } from 'antd';
const DataForm = () => {
    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    const { Option } = Select;
    const style = {
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderRadius: 'revert',
        borderColor: 'black'
    };
    return (

        <div style={{ border: '2px dashed blue', borderRadius: '50px', padding: '20px' }}>
            <Form

                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={20}>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>First Name</label>
                        <Form.Item

                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input style={style} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>Last Name</label>
                        <Form.Item
                            // label=" Name"
                            name="last_name"
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input style={style} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>Age</label>
                        <Form.Item
                            // label="Age"
                            name="age"
                            rules={[{ required: true, message: 'Please input your age!' }]}
                        >
                            <Input style={style} />
                        </Form.Item></Col>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>Gender</label>
                        <Form.Item
                            // label="Age"
                            name="age"
                            rules={[{ required: true, message: 'Please input your age!' }]}
                        >
                            <Select
                                showSearch
                                // style={{ width: 200 }}
                                placeholder="Select a person"
                                // optionFilterProp="children"
                                // onChange={onChange}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={style}
                            >
                                <Option value="jack">Jack</Option>
                                <Option value="lucy">Lucy</Option>
                                <Option value="tom">Tom</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>Blood Group</label>
                        <Form.Item
                            // label=""
                            name="bloodgroup"
                            rules={[{ required: true, message: 'Please input your bloodgroup!' }]}
                        >
                            <Input style={style} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>How Often you sweat</label>
                        <Form.Item
                            // label="How Often you sweat"
                            name=""
                            rules={[{ required: true, message: 'Please input your !' }]}
                        >
                            <Input style={style} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>Height in Inches</label>
                        <Form.Item
                            // label="Height in Inches"
                            name="height"
                            rules={[{ required: true, message: 'Please input your height!' }]}
                        >
                            <Input style={style} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} xxl={12}>
                        <label>Weight in Kg</label>
                        <Form.Item
                            // label="Weight in Kg"
                            name="weight"
                            rules={[{ required: true, message: 'Please input your weight!' }]}
                        >
                            <Input style={style} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>By Submitted the form, I here by accepted the <a>Privacy Policy</a></Checkbox>
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>

            </Form>
        </div>
    );
};
export default DataForm;
