import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import Loader from "react-js-loader";
import { faDroplet } from '@fortawesome/free-solid-svg-icons';
import './index.css'
import SectionC from './sectionC';
import SectionD from './sectionD';
import SectionB from './sectionB';
export default function Hydration() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const handleMouseOver1 = () => {
    setIsHovered1(true);
  };
  const handleMouseOut1 = () => {
    setIsHovered1(false);
  };
  const handleMouseOver2 = () => {
    setIsHovered2(true);
  };
  const handleMouseOut2 = () => {
    setIsHovered2(false);
  };
  const Style = () => {
    return {
      fontSize: 'xxx-large',
      color: 'black',
      fontWeight: '600',
    };
  };
  const BoxStyle = () => {
    return {
      border: '1px solid #e6e600',
      padding: '4px 8px',
      borderRadius: '14px',
      fontSize: '20px',
      fontWeight: '500',
      color: isHovered ? 'white' : 'black',
      transition: 'background-color 0.4s',
      backgroundColor: isHovered ? '#e6e600' : 'transparent',
    };
  };
  const BoxStyle1 = () => {
    return {
      border: '1px solid #e6e600',
      padding: '4px 8px',
      borderRadius: '14px',
      fontSize: '20px',
      fontWeight: '500',
      transition: 'background-color 0.4s',
      color: isHovered1 ? 'white' : 'black',
      backgroundColor: isHovered1 ? '#e6e600' : 'transparent',
    };
  };
  const BoxStyle2 = () => {
    return {
      border: '1px solid #e6e600',
      padding: '4px 8px',
      borderRadius: '14px',
      // fontSize: '20px',
      fontWeight: '500',
      color: isHovered2 ? 'white' : 'black',
      transition: 'background-color 0.4s',
      backgroundColor: isHovered2 ? '#e6e600' : 'transparent',
      fontSize: isHovered2 ? '22px' : '20px'
    };
  };
  return (
    <>
      <section className='container'>
        <Row>
          <Col xs={24} sm={24} md={24} lg={18} xl={16} xxl={14}>
            <div style={Style()}> {/* Calling the Style function inside curly braces */}
              <p style={{marginTop:"10px"}}>
                Unlock Your Peak Performance,
                Get Real Time <span style={{ margin: '0px 4px 0px 4px', color: ' #0099ff' }}>Hydration</span> Insights
                <p style={{ display: 'flex', marginTop: '0px' }}>Now <Loader type="bubble-top" bgColor={'black'} color={'black'} size={70} /></p>
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={10} >
          
            <Col style={{marginBottom:"10px"}} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <div style={BoxStyle()}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <FontAwesomeIcon icon={faDroplet} style={{ marginRight: '10px' }} />Drink Pently Of Water
              </div>
            </Col>
            <Col style={{marginBottom:"10px"}} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <div style={BoxStyle1()}
                onMouseOver={handleMouseOver1}
                onMouseOut={handleMouseOut1}
              >
                <FontAwesomeIcon icon={faDroplet} style={{ marginRight: '10px' }} />Avoid Alcohol
              </div>
            </Col>
            <Col style={{marginBottom:"10px"}} xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <div style={BoxStyle2()}
                onMouseOver={handleMouseOver2}
                onMouseOut={handleMouseOut2}
              >
                <FontAwesomeIcon icon={faDroplet} style={{ marginRight: '10px' }} />Eat Healthy Food
              </div>
            </Col>
        </Row>
        <div style={{ display: 'flex' }}>

          <div style={{ display: 'flex' }}>
            <div>
              <p style={{ color: 'blue', fontSize: 'x-large', marginTop: '50px' }}>Scroll Down</p></div>
            <div class="scroll-down-dude" style={{ marginTop: '50px', marginLeft: '15px' }}></div>
          </div>
        </div>
      </section>
      <section>
        <SectionB></SectionB>
        <SectionC></SectionC>
        <SectionD></SectionD>
      </section>
      <section>

      </section>
    </>
  );
}
