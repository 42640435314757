import { Col, Image, Row } from 'antd';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
const PartC=(props)=> {
  return (
    <>
      <h2>{props.a}</h2>
      <Row gutter={30} style={{justifyContent:'center'}}>
        <Col xs={24}sm={24} md={12} lg={12} xl={10} xxl={10}>
          <Image src={props.img} />
        </Col>
        <Col  xs={24}sm={24} md={12} lg={12} xl={14} xxl={14}>
          <div>
            <p className='why-importance'>
            {props.b}
            </p>
          </div>
          <div style={{marginTop:'65px'}}>
            <p ><FontAwesomeIcon icon={faCircleCheck} style={{color:'green',backgroundColor:'white',marginRight:'5px'}}/>{props.c}</p>
            <p><FontAwesomeIcon icon={faCircleCheck} style={{color:'green',backgroundColor:'white',marginRight:'5px'}}/>{props.d}</p>
            <p><FontAwesomeIcon icon={faCircleCheck} style={{color:'green',backgroundColor:'white',marginRight:'5px'}}/>{props.e}</p>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default PartC;


