import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import Loader from "react-js-loader";
import { faDroplet } from '@fortawesome/free-solid-svg-icons';
import SectionB from './sectionb';
import SectionD from './sectiond';
import SectionC from './sectionc';
export default function Glucose() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const handleMouseOver1 = () => {
    setIsHovered1(true);
  };
  const handleMouseOut1 = () => {
    setIsHovered1(false);
  };
  const handleMouseOver2 = () => {
    setIsHovered2(true);
  };
  const handleMouseOut2 = () => {
    setIsHovered2(false);
  };
  const Style = () => {
    return {
      fontSize: 'xxx-large',
      color: 'black',
      fontWeight: '600'
    };
  };
  const BoxStyle = () => {
    return {
      border: '1px solid #e6e600',
      padding: '4px 8px',
      borderRadius: '14px',
      fontSize: '20px',
      fontWeight: '500',
      color: isHovered ? 'white' : 'black',
      transition: 'background-color 0.4s',
      backgroundColor: isHovered ? '#e6e600' : 'transparent',
    };
  };
  const BoxStyle1 = () => {
    return {
      border: '1px solid #e6e600',
      padding: '4px 8px',
      borderRadius: '14px',
      fontSize: '20px',
      fontWeight: '500',
      transition: 'background-color 0.4s',
      color: isHovered1 ? 'white' : 'black',
      backgroundColor: isHovered1 ? '#e6e600' : 'transparent',
    };
  };
  const BoxStyle2 = () => {
    return {
      border: '1px solid #e6e600',
      padding: '4px 8px',
      borderRadius: '14px',
      // fontSize: '20px',
      fontWeight: '500',
      color: isHovered2 ? 'white' : 'black',
      transition: 'background-color 0.4s',
      backgroundColor: isHovered2 ? '#e6e600' : 'transparent',
      fontSize: isHovered2 ? '22px' : '20px'
    };
  };
  return (
    <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={12}>
            <div style={Style()}> {/* Calling the Style function inside curly braces */}
              <p >
                Transform Your Health Journey,
                Harness the Power of  <span style={{ margin: '0px 4px 0px 4px', color: ' #0099ff' }}>Glucose</span> 
                <p style={{ display: 'flex', marginTop: '0px' }}>Monitoring <Loader type="bubble-top" bgColor={'black'} color={'black'} size={70} /></p>
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={20} >
          
            <Col  xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}>
              <div style={BoxStyle()}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <FontAwesomeIcon icon={faDroplet} style={{ marginRight: '10px' }} />Low Carbs
              </div>
            </Col>
            <Col  xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}>
              <div style={BoxStyle1()}
                onMouseOver={handleMouseOver1}
                onMouseOut={handleMouseOut1}
              >
                <FontAwesomeIcon icon={faDroplet} style={{ marginRight: '10px' }} />Enough Sleep
              </div>
            </Col>
            <Col  xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{marginBottom:"20px"}}>
              <div style={BoxStyle2()}
                onMouseOver={handleMouseOver2}
                onMouseOut={handleMouseOut2}
              >
                <FontAwesomeIcon icon={faDroplet} style={{ marginRight: '10px' }} />Say No to Stress
              </div>
            </Col>
        </Row>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}>
            <div>
              <p style={{ color: 'blue', fontSize: 'x-large', marginTop: '50px' }}>Scroll Down</p></div>
            <div class="scroll-down-dude" style={{ marginTop: '50px', marginLeft: '15px' }}></div>
          </div>
        </div>
 <section>
      <SectionB/>
       <SectionC/>
        <SectionD/>
      </section>
    </>
  );
}
